<template>
  <el-card class="form-container" shadow="never">
    <el-form
      :model="bulk"
      ref="createBulkForm"
      label-width="150px"
      style="width: 600px"
      size="small"
    >
      <el-form-item label="Product: ">
        <el-input v-model="productDisplay" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="Captain: ">
        <el-input v-model="captainDisplay" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item
        label="Max Member: "
        prop="memberLimit"
        :rules="[
          {
            required: true,
            message: 'Please enter max member limit',
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="bulk.memberLimit"></el-input>
      </el-form-item>
      <el-form-item
        label="Threshold: "
        prop="successMemberThreshold"
        :rules="[
          {
            required: true,
            message: 'Please enter success member threshold',
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="bulk.successMemberThreshold"></el-input>
      </el-form-item>
      <el-form-item
        label="Start time: "
        prop="startTime"
        :rules="[
          {
            required: true,
            message: 'Please select start time',
            trigger: 'blur',
          },
        ]"
      >
        <el-date-picker
          v-model="bulk.startTime"
          type="datetime"
          placeholder="Start Date"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="End time: "
        prop="endTime"
        :rules="[
          {
            required: true,
            message: 'Please select end time',
            trigger: 'blur',
          },
        ]"
      >
        <el-date-picker
          v-model="bulk.endTime"
          type="datetime"
          placeholder="End Date"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit('createBulkForm')"
          align="center"
          >Submit</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { updateBulk } from '@/api/bulk';

const defaultBulk = {
  id: null,
  memberLimit: '99',
  successMemberThreshold: '2',
  startTime: null,
  endTime: null,
  timeZone: 'America/Toronto',
};

export default {
  name: 'EditBulk',
  data() {
    return {
      bulk: { ...defaultBulk },
      productDisplay: null,
      captainDisplay: null,
    };
  },
  created() {
    this.bulk.id = this.$route.query.id;
    this.productDisplay = this.$route.query.productDisplay;
    this.captainDisplay = this.$route.query.captainDisplay;
    this.bulk.startTime = this.$route.query.startTime;
    this.bulk.endTime = this.$route.query.endTime;
    this.bulk.successMemberThreshold = this.$route.query.thr;
    this.bulk.memberLimit = this.$route.query.max;
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(
            'Edit Bulk? Bulk would immediately published to users!',
            'Warning',
            {
              confirmButtonText: 'Yes',
              cancelButtonText: 'Cancel',
              type: 'Warning',
            },
          ).then(() => {
            updateBulk(this.bulk).then((response) => {
              this.$message({
                message: 'Success',
                type: 'success',
                duration: 1000,
              });
              this.$router.back();
            });
          });
        } else {
          this.$message({
            message: 'Form Invalid',
            type: 'error',
            duration: 1000,
          });
          return false;
        }
      });
    },
  },
};
</script>
